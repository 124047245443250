import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import SingleValueSelectDropdown from '../../Tools/SingleValueSelectDropdown';
import axios from 'axios';

type BConnectClient = {
  bconnect_id: string;
  name: string;
};

type DefaultSingleValue = {
  optionLabel: JSX.Element;
  label: string;
  group: string;
  value: string | undefined;
};

const BConnectClientsSearchField = ({
  onChange,
  endpoint,
  searchKey,
  refresh
}: {
  onChange: (data: string[] | string | null) => void;
  searchKey?: string;
  endpoint?: string;
  refresh?: boolean;
}) => {
  const [clients, setClients] = useState<BConnectClient[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [defaultSingleValue, setDefaultSingleValue] = useState<DefaultSingleValue | null>(null);

  useEffect(() => {
    const getUrl = () => {
      let url = endpoint;
      if (searchKey) url = search.length > 2 ? endpoint + '?' + searchKey + '=' + search : '';
      return url;
    };
    const url = getUrl();
    if (url) {
      setIsLoading(true);
      axios
        .get(url)
        .then((response) => {
          setClients('results' in response.data ? response.data.results : response.data);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [endpoint, searchKey, search]);

  const getOption = (client: BConnectClient) => {
    return {
      optionLabel: client.name,
      label: client.name,
      value: client.bconnect_id
    };
  };

  const options = clients
    ?.map((client) => getOption(client))
    .sort((a, b) => a.label.localeCompare(b.label));

  const clear = () => {
    setDefaultSingleValue(null);
    setClients([]);
    setSearch('');
    setIsLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      clear();
    }
  }, [refresh]);

  return (
    <Box sx={{ minHeight: '40px', position: 'relative' }}>
      <SingleValueSelectDropdown
        required={false}
        options={options}
        onChange={(data) => onChange(data ? (data.value as string) : null)}
        defaultValue={defaultSingleValue}
        onSearch={setSearch}
        searchable={searchKey !== undefined}
      />
      {isLoading && (
        <Box sx={{ position: 'absolute', right: 40, top: 10 }}>
          <CircularProgress size={20} />
        </Box>
      )}
    </Box>
  );
};

export default BConnectClientsSearchField;
