import React from 'react';
import { ListPage, AppPaper, BirdzTitle, Action } from '@applications-terrains/birdz-react-library';
import { SearchField } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import ReRunJobHandler from './ReRunJobHandler';
import DisplayStatus from './DisplayStatus';
import useErpOptions from '../Erps/useErpOptions';
import { Erp } from '../Erps/ErpForm';
import { getERPNameAndVersion } from '../Erps/utils';

const JobsExecutions = () => {
  const endpoint = '/api/boi/extractor/jobs-executions/';
  const ErpOtions = useErpOptions();

  const listFields = [
    {
      name: 'started_at',
      label: 'Début',
      transform: (value: any) => {
        if (value) {
          return 'Le ' + moment(value).format('DD-MM-YYYY') + ' à ' + moment(value).format('HH:mm');
        } else {
          return '';
        }
      }
    },
    {
      name: 'ended_at',
      label: 'Fin',
      transform: (_: any, row: any) => {
        const value = row.executed_at !== null ? row.executed_at : row.started_at;
        if (value) {
          return 'Le ' + moment(value).format('DD-MM-YYYY') + ' à ' + moment(value).format('HH:mm');
        } else {
          return '';
        }
      }
    },
    {
      name: 'job',
      label: 'Job',
      transform: (_item: any, row: any) => {
        return row?.job?.name;
      }
    },
    {
      name: 'client_name',
      label: 'Client',
      transform: (_item: any, row: any) => {
        return row?.job?.client_name;
      }
    },
    {
      name: 'count_exported_interventions',
      label: 'Nb interventions exportées',
      transform: (count: any) => {
        return count ?? 0;
      }
    },
    {
      name: 'erp',
      label: 'ERP',
      transform: (_: any, row: { job: { erp_name: string; erp_version: string } }) => {
        return row?.job?.erp_name
          ? getERPNameAndVersion({
              name: row?.job?.erp_name,
              version: row?.job?.erp_version
            } as Erp)
          : '-';
      }
    },
    {
      name: 'job_format',
      label: 'Format',
      transform: (_item: any, row: any) => {
        return row?.job?.export_format_name ?? '-';
      }
    },
    {
      name: 'status',
      label: 'Statut',
      transform: (status: any) => {
        return <DisplayStatus statusLabel={status} />;
      }
    },
    {
      name: 'trigger_type',
      label: 'Déclenchement',
      transform: (trigger_type: any) => {
        switch (trigger_type) {
          case 'AUTOMATICALLY':
            return 'Automatique';
          case 'MANUALLY':
            return 'Manuel';
          default:
            return '-';
        }
      }
    }
  ];

  const actions: Action[] = [
    {
      name: 'details',
      label: 'Voir les détails',
      render: (row: any) => {
        return (
          <IconButton component={Link} to={`/boi/extractor/jobs-executions/details/${row.id}`}>
            <Visibility fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 're-run',
      label: 'relancer le job',
      render: (row: any) => {
        return <ReRunJobHandler exportObj={row} />;
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'job_name',
      label: 'Job',
      options: {
        source: endpoint,
        formatResponse: (response: any) => {
          return {
            value: response?.job?.name,
            label: response?.job?.name
          };
        },
        searchIsMulti: false
      }
    },
    {
      name: 'client',
      label: 'Client',
      options: {
        source: endpoint,
        formatResponse: (response: any) => {
          return { value: response?.job?.client, label: response?.job?.client_name };
        },
        searchIsMulti: false
      }
    },
    {
      name: 'started_at_after',
      label: 'Démarré entre le ',
      type: 'datepicker',
      className: 'col-sm-6'
    },
    {
      name: 'started_at_before',
      label: 'et le',
      type: 'datepicker',
      className: 'col-sm-6'
    },
    {
      name: 'erp',
      label: 'ERP',
      type: 'select',
      options: {
        values: ErpOtions
      }
    },
    {
      name: 'export_format',
      label: 'Format',
      options: {
        source: '/api/boi/extractor/export-formats/',
        formatResponse: (response: any) => {
          return {
            value: response.id,
            label: response.name
          };
        },
        searchIsMulti: false
      }
    },
    {
      name: 'status',
      label: 'Statut',
      type: 'select',
      options: {
        values: [
          { value: 'IN_PROGRESS', label: 'En cours' },
          { value: 'FILE_ERROR', label: 'Erreur fichier' },
          { value: 'TO_DELIVER', label: 'A livrer' },
          { value: 'DELIVERY_ERROR', label: 'Erreur livraison' },
          { value: 'DELIVERED_SUCCESSFULLY', label: 'Livré avec succès' }
        ]
      }
    }
  ];

  return (
    <AppPaper>
      <BirdzTitle>Historique des exports</BirdzTitle>

      <ListPage
        endpoint={endpoint}
        fields={listFields}
        actions={actions}
        searchFields={searchFields}
      />
    </AppPaper>
  );
};

export default JobsExecutions;
