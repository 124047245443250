import React, { ReactNode, useEffect, useState } from 'react';
import {
  BirdzDialog,
  useDialog,
  InputField,
  SelectField,
  RadioField
} from '@applications-terrains/birdz-react-library';
import { useNavigate, useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Box, Button, CircularProgress, Grid, Paper } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { Formik } from 'formik';
import { formatToSelectOptions, useGetErps } from '../../../hooks/datarefs';
import { ExportFormatSettingsType } from './ExportFormatSettings';
import formatErrorNotifObject from '../../Tools/formatErrorNotifObject';

type ParamTypes = {
  id: string;
};

export type ExportFormat = {
  id: number;
  name: string;
  description: string;
  version: string;
  active: boolean;
  erp: number;
  file_type: string;
  file_name: string;
  file_encoding: string;
  line_break: string;
  column_seperator: string;
  text_delimiter: string | null;
  jobs_count: number;
  settings: ExportFormatSettingsType[];
  erp_name: string;
  erp_version: string;
};

const fileEncoding = [
  { value: 'ANSI', label: 'ANSI' },
  { value: 'UTF8', label: 'UTF8' }
];

const lineBreak = [
  { value: 'LINUX', label: 'LINUX' },
  { value: 'WINDOWS', label: 'WINDOWS' }
];

const fileTypes = [
  { value: 'CSV', label: 'CSV' },
  { value: 'PDF', label: 'PDF' }
];

const translations = {
  name: { label: 'Nom', required: true },
  version: { label: 'Version', required: true },
  file_type: { label: 'Type de fichier', required: true },
  file_name: { label: 'Nom du fichier', required: true },
  file_encoding: { label: 'Encodage du fichier', required: true },
  line_break: { label: 'Retour à la ligne', required: true },
  active: { label: 'État', required: false },
  erp: { label: 'ERP', required: false },
  description: { label: 'Description', required: false }
};

const ExportFormatForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<Partial<ExportFormat>>({});
  const { notif, notifOptions } = useNotif();
  const { id } = useParams<ParamTypes>();
  const navigate = useNavigate();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { data: erps } = useGetErps();
  const endpoint = '/api/boi/extractor/export-formats/';

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios.get(`${endpoint}${id}/`).then((response: AxiosResponse<ExportFormat>) => {
        setExportFormat(response.data);
        setIsLoading(false);
      });
    }
  }, [id]);

  const saveExportFormat = (exportFormat: ExportFormat) => {
    setSubmitInProgress(true);
    const method = id ? 'put' : 'post';
    const url = `${endpoint}${id ? id + '/' : ''}`;
    axios[method](url, exportFormat)
      .then(() => {
        notif({
          type: 'success',
          content: "Le format d'export a bien été enregistré"
        });
        setTimeout(() => {
          navigate('/boi/extractor/export-formats/list');
        }, 2000);
      })
      .catch((err) => {
        const errorsList = err?.response?.data;
        notif(
          formatErrorNotifObject({
            error:
              errorsList || "Une erreur est survenue lors de l'enregistrement du format d'export",
            translations
          })
        );
      })
      .finally(() => {
        setSubmitInProgress(false);
      });
  };

  const Wrapper = ({ children }: { children: ReactNode }) => (
    <Paper className="w-100 h-100 d-flex justify-content-center align-items-center">
      {children}
    </Paper>
  );

  const displayLabel = (fieldName: string): string => {
    const field = translations[fieldName as keyof typeof translations];
    return field.label + (field.required ? ' *' : '');
  };

  return (
    <>
      {isLoading || !erps ? (
        <Wrapper>
          <div className="d-flex align-items-center">
            <h4>Chargement en cours...</h4>
            <CircularProgress size={20} sx={{ ml: 2 }} />
          </div>
        </Wrapper>
      ) : erps && erps.length === 0 ? (
        <Wrapper>
          <h4>
            Aucun Erp disponible. Merci d'en créer un{' '}
            <a style={{ textDecoration: 'none' }} href="/boi/extractor/erps/add">
              ici
            </a>
            .
          </h4>
        </Wrapper>
      ) : (
        <Paper variant="outlined" square sx={{ p: 2 }}>
          <h3>{id ? "Modifier le format d'export" : "Ajouter le format d'export"}</h3>

          <Formik
            initialValues={exportFormat}
            enableReinitialize={true}
            onSubmit={async (values: any) => {
              const formattedValues = {
                ...values,
                text_delimiter: values.text_delimiter || null
              };
              saveExportFormat(formattedValues);
            }}
          >
            {(props: any) => {
              const { errors, setSubmitting, handleSubmit, values } = props;
              return (
                <form
                  id="topForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSubmitting(true);
                    // Check
                    if (Object.keys(errors).length === 0) {
                      handleSubmit();
                    }
                  }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid alignItems={'center'} item xs={2}>
                      {displayLabel('name')}
                    </Grid>
                    <Grid alignItems={'center'} item xs={4}>
                      <InputField name="name" type="text" size="small" fullWidth />
                    </Grid>
                    <Grid alignItems={'center'} item xs={2} sx={{ pl: 1 }}>
                      {displayLabel('active')}
                    </Grid>
                    <Grid alignItems={'center'} item xs={4}>
                      <RadioField
                        name="active"
                        options={[
                          { value: true, label: 'Actif' },
                          { value: false, label: 'Inactif' }
                        ]}
                      />
                    </Grid>
                    <Grid alignItems={'center'} item xs={2}>
                      {displayLabel('version')}
                    </Grid>
                    <Grid alignItems={'center'} item xs={4}>
                      <InputField name="version" type="text" size="small" fullWidth />
                    </Grid>
                    <Grid alignItems={'center'} item xs={2}>
                      {displayLabel('erp')}
                    </Grid>
                    <Grid alignItems={'center'} item xs={4}>
                      <SelectField
                        name="erp"
                        options={formatToSelectOptions(erps, {
                          label: 'name'
                        })}
                        required={true}
                        sx={{ width: 300 }}
                      />
                    </Grid>
                    <Grid alignItems={'center'} item xs={2}>
                      {displayLabel('file_type')}
                    </Grid>
                    <Grid alignItems={'center'} item xs={4}>
                      <SelectField name="file_type" options={fileTypes} />
                    </Grid>
                  </Grid>

                  <Box sx={{ mt: 4 }}>
                    <h3>Détail du format de fichier</h3>
                    <Grid container spacing={2} alignItems="center">
                      <Grid alignItems={'center'} item xs={2}>
                        {displayLabel('file_name')}
                      </Grid>
                      <Grid alignItems={'center'} item xs={4}>
                        <InputField name="file_name" type="text" size="small" fullWidth />
                      </Grid>
                      <Grid alignItems={'center'} item xs={2}>
                        {displayLabel('file_encoding')}
                      </Grid>
                      <Grid alignItems={'center'} item xs={4}>
                        <SelectField name="file_encoding" options={fileEncoding} />
                      </Grid>
                      <Grid alignSelf="flex-start" item xs={2}>
                        {displayLabel('description')}
                      </Grid>
                      <Grid alignSelf="flex-start" item xs={4}>
                        <InputField
                          name="description"
                          type={'textarea'}
                          multiline
                          rows={3}
                          fullWidth
                        />
                      </Grid>
                      <Grid alignSelf="flex-start" item xs={2}>
                        {displayLabel('line_break')}
                      </Grid>
                      <Grid alignSelf="flex-start" item xs={4} sx={{ pt: '24px' }}>
                        <SelectField name="line_break" options={lineBreak} />
                      </Grid>
                      {values?.file_type === 'CSV' && (
                        <>
                          <Grid alignItems={'center'} item xs={2}>
                            Séparateur colonne
                          </Grid>
                          <Grid alignItems={'center'} item xs={4}>
                            <InputField
                              name="column_seperator"
                              type="text"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          <Grid alignItems={'center'} item xs={2}>
                            Délimiteur texte
                          </Grid>
                          <Grid alignItems={'center'} item xs={4}>
                            <InputField name="text_delimiter" type="text" size="small" fullWidth />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      form="topForm"
                      sx={{ m: 2 }}
                      disabled={submitInProgress}
                    >
                      {submitInProgress && <CircularProgress size={18} thickness={5} />} Enregistrer
                    </Button>

                    <Button
                      className="btn-space"
                      onClick={() => {
                        confirmDialog({
                          title: 'Annuler les saisies',
                          content: 'Souhaitez-vous annuler vos saisies ?',
                          onValidate: () => {
                            closeDialog();
                            navigate(-1);
                          },
                          onCancel: () => {
                            closeDialog();
                          },
                          okButtonLabel: 'Oui',
                          cancelButtonLabel: 'Non'
                        });
                      }}
                    >
                      Annuler
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
          <BirdzNotif options={notifOptions} />
          <BirdzDialog options={dialogOptions} />
        </Paper>
      )}
    </>
  );
};

export default ExportFormatForm;
