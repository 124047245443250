import React, { useEffect, useState } from 'react';
import MultiValuesSelectDropdown from '../Tools/MultiValuesSelectDropdown';
import { defaultSize } from '../../hooks/datarefs';
import { Box, Chip, Skeleton } from '@mui/material';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import PlaceIcon from '@mui/icons-material/Place';
import SingleValueSelectDropdown from '../Tools/SingleValueSelectDropdown';
import { isArray } from 'lodash';
import axios from 'axios';

type Contract = {
  contract?: string;
  contract_management_code?: string;
  contract_ve: string;
  client: string;
  contract_client: string;
};

type DefaultSingleValue = {
  optionLabel: JSX.Element;
  label: string;
  group: string;
  value: string | undefined;
};

export const OptionLabel = ({ contract }: { contract: Contract }) => (
  <Box className="d-flex align-items-center">
    <Box sx={{ minWidth: '150px' }}>
      <Chip
        label={
          <div className="d-flex align-items-center">
            <Grid3x3Icon sx={{ mr: 1 }} fontSize="small" /> {contract.contract_management_code}
          </div>
        }
      />
    </Box>
    <Box className="d-flex align-items-center">
      <PlaceIcon sx={{ mr: 1 }} fontSize="small" /> {contract.contract_ve}
    </Box>
  </Box>
);

const ContractsDropdown = ({
  onChange,
  value,
  isMulti = true,
  endpoint,
  required,
  searchKey,
  refresh
}: {
  value?: string[] | string;
  onChange: (data: string[] | string | null) => void;
  searchKey?: string;
  isMulti?: boolean;
  endpoint?: string;
  required?: boolean;
  refresh?: boolean;
}) => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [defaultSingleValue, setDefaultSingleValue] = useState<DefaultSingleValue | null>(null);
  const [defaultMultiValue, setDefaultMultiValue] = useState<DefaultSingleValue[]>([]);

  useEffect(() => {
    const getUrl = () => {
      let url = endpoint || `/api/boi/params/contracts/?size=${defaultSize}`;
      if (searchKey) url = search.length > 2 ? endpoint + '?' + searchKey + '=' + search : '';
      return url;
    };
    const url = getUrl();
    url &&
      axios.get(url).then((response) => {
        setContracts('results' in response.data ? response.data.results : response.data);
      });
  }, [endpoint, searchKey, search]);

  const getLabel = (contract: Contract) =>
    contract.client + ' / ' + contract.contract_ve + ' (' + contract.contract_management_code + ')';

  const getOption = (contract: Contract) => {
    const formattedContract = {
      ...contract,
      group: contract.client || contract.contract_client || '',
      client: contract.client || contract.contract_client || '',
      contract_management_code:
        'contract_management_code' in contract
          ? contract.contract_management_code
          : contract.contract
    };
    return {
      optionLabel: <OptionLabel contract={formattedContract} />,
      label: getLabel(formattedContract),
      group: formattedContract.group.trim(),
      value: formattedContract.contract_management_code
    };
  };

  const options = contracts
    ?.map((contract) => getOption(contract))
    .filter((contract) => contract.group)
    .sort((a, b) => a.group.localeCompare(b.group));

  useEffect(() => {
    if (value) {
      if (contracts) {
        if (!isMulti) {
          const contract = contracts?.find((el) => el.contract_management_code === value);
          if (typeof value === 'string' && contract !== undefined) {
            setDefaultSingleValue(getOption(contract as Contract));
            setIsLoading(false);
          }
        } else {
          if (isArray(value)) {
            const defaultValue = value
              ?.map((contractCode: string) => {
                const contract = contracts?.find(
                  (contract) => contract.contract_management_code === contractCode
                );
                return contract && getOption(contract);
              })
              .filter((el): el is NonNullable<typeof el> => el !== undefined);

            setDefaultMultiValue(defaultValue as DefaultSingleValue[]);
            setIsLoading(false);
          }
        }
      }
    } else contracts && setIsLoading(false);
  }, [value, contracts, isMulti]);

  const clear = () => {
    setDefaultSingleValue(null);
    setDefaultMultiValue([]);
    setContracts([]);
    setSearch('');
    setIsLoading(true);
  };

  useEffect(() => {
    if (refresh === true) {
      clear();
    }
  }, [refresh]);

  return (
    <Box sx={{ minHeight: '40px' }}>
      {isLoading ? (
        <Skeleton variant="rounded" width={'100%'} height={40} />
      ) : (
        <>
          {isMulti ? (
            <MultiValuesSelectDropdown
              defaultValue={defaultMultiValue}
              onChange={(data) => onChange(data?.map((contract) => contract.value as string))}
              options={options}
            />
          ) : (
            <SingleValueSelectDropdown
              required={required}
              options={options}
              onChange={(data) => onChange(data ? (data.value as string) : null)}
              defaultValue={defaultSingleValue}
              onSearch={setSearch}
              searchable={searchKey !== undefined}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ContractsDropdown;
