import React from 'react';
import {
  ListPage,
  AppPaper,
  BirdzTitle,
  useDialog,
  BirdzDialog
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif, SearchField } from '@applications-terrains/birdz-react-library';
import Delete from '@mui/icons-material/Delete';
import ImportHandler from '../import/ImportHandler';
import Visibility from '@mui/icons-material/Visibility';
import { Correspondance } from '../types';
import { PiFileCsv } from 'react-icons/pi';
import { useFetchContext } from '../../../../contexts/fetchContext';
import useErpOptions from '../../Erps/useErpOptions';
import { getERPNameAndVersion } from '../../Erps/utils';
import { Erp } from '../../Erps/ErpForm';

const CorrespondancesList = () => {
  const endpoint = '/api/boi/extractor/data-mapping/';
  const { notif, notifOptions } = useNotif();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const ErpOptions = useErpOptions();

  const fetchContext = useFetchContext();

  const listFields = [
    {
      name: 'erp_name',
      label: 'ERP',
      orderable: true,
      transform: (_: string, correspondance: { erp_name: string; erp_version: string }) => {
        return getERPNameAndVersion({
          name: correspondance.erp_name,
          version: correspondance.erp_version
        } as Erp);
      }
    },
    { name: 'name', label: 'Nom', orderable: false },
    {
      name: 'translations_count',
      label: 'Nombre de correspondances',
      orderable: false
    }
  ];

  const actions = [
    {
      name: 'details',
      label: 'Voir les détails',
      render: (correspondance: Correspondance) => {
        return (
          <IconButton
            component={Link}
            to={`/boi/extractor/correspondances/details/${correspondance.id}`}
          >
            <Visibility fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-correspondance',
      label: 'Supprimer la correspondance',
      render: (model: { id: number }) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer la liste de correspondances',
                content: 'Êtes-vous sûr de vouloir supprimer cette liste de correspondances ?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: 'La liste de correspondances a été supprimée avec succès',
                        type: 'success'
                      });
                      fetchContext.toggleNeedToFetch(true);
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'erp',
      label: 'ERP',
      type: 'select',
      options: {
        values: ErpOptions
      }
    },
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint,
        searchIsMulti: false
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste de correspondances</BirdzTitle>
        </Grid>
        <Grid item>
          <ImportHandler />
        </Grid>
      </Grid>
      {!fetchContext.needToFetch && (
        <ListPage
          fields={listFields}
          actions={actions}
          searchFields={searchFields}
          endpoint={endpoint}
          exportButtons={[
            {
              name: 'Exporter en csv',
              type: 'csv',
              icon: <PiFileCsv />,
              requestEndpoint: '/api/boi/extractor/data-mapping-export/',
              keepQueryParams: false,
              onSuccess: () => {
                notif({
                  type: 'success',
                  content: 'Export réussi'
                });
              }
            }
          ]}
        />
      )}
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default CorrespondancesList;
