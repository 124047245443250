import React from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import { Link, useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif, SearchField } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { ExportFormat } from './ExportFormatForm';
import SettingsIcon from '@mui/icons-material/Settings';
import { Erp } from '../Erps/ErpForm';
import { getERPNameAndVersion } from '../Erps/utils';
import useErpOptions from '../Erps/useErpOptions';

const ExportFormats = () => {
  const endpoint = '/api/boi/extractor/export-formats/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const navigate = useNavigate();
  const ErpOptions = useErpOptions();

  const listFields = [
    {
      name: 'erp_name',
      label: 'ERP',
      orderable: true,
      transform: (_: string, exportFormat: ExportFormat) => {
        return getERPNameAndVersion({
          name: exportFormat.erp_name,
          version: exportFormat.erp_version
        } as Erp);
      }
    },
    { name: 'name', label: 'Nom', orderable: true },
    { name: 'version', label: 'Version', orderable: true },
    { name: 'file_type', label: 'Type', orderable: true },
    { name: 'jobs_count', label: 'Jobs' },
    {
      name: 'active',
      label: 'Actif/Inactif',
      render: (erp: Erp) => {
        erp.active ? 'Actif' : 'Inactif';
      }
    }
  ];

  const actions = [
    {
      name: 'duplicate',
      label: "Dupliquer le format d'export",
      render: (exportFormatListItem: any) => {
        return (
          <IconButton
            onClick={async () => {
              const response: AxiosResponse<ExportFormat> = await axios.get(
                `/api/boi/extractor/export-formats/${exportFormatListItem.id}/`
              );
              const exportFormat: Partial<ExportFormat> = response.data;
              exportFormat.name = `${exportFormat.name} (copie)`;
              delete exportFormat?.id;
              axios.post('/api/boi/extractor/export-formats/', exportFormat).then(() => {
                notif({
                  content: "Le format d'export a été dupliqué avec succès",
                  type: 'success'
                });
              });
              setTimeout(() => {
                navigate(0);
              }, 2000);
            }}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'edit',
      label: "Modifier le format d'export",
      render: (exportFormat: any) => {
        return (
          <IconButton component={Link} to={`/boi/extractor/export-formats/edit/${exportFormat.id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'settings',
      label: "Parametrer le format d'export",
      render: (exportFormat: any) => {
        return (
          <IconButton
            component={Link}
            to={`/boi/extractor/export-formats/settings/${exportFormat.id}`}
          >
            <SettingsIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-export-formats',
      label: "Supprimer le format d'export",
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: "Supprimer le format d'export",
                content: "Êtes-vous sûr de vouloir supprimer ce format d'export?",
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: "Le format d'export a été supprimé avec succès",
                        type: 'success'
                      });
                      setTimeout(() => {
                        navigate(0);
                      }, 2000);
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint
      }
    },
    {
      name: 'file_type',
      label: 'Type',
      type: 'select',
      options: {
        values: [
          { label: 'CSV', value: 'CSV' },
          { label: 'PDF', value: 'PDF' }
        ]
      }
    },
    {
      name: 'erp',
      label: 'ERP',
      type: 'select',
      options: {
        values: ErpOptions
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste des formats d'export</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to="/boi/extractor/export-formats/add">
            <Button variant="contained" sx={{ mt: 3 }}>
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un format d'export
            </Button>
          </Link>
        </Grid>
      </Grid>

      <ListPage
        endpoint={endpoint}
        fields={listFields}
        actions={actions}
        searchFields={searchFields}
      />
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default ExportFormats;
